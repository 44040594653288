let initialised = false;

export default function () {

    const nuxtApp = useNuxtApp();

    const init = async function () {
        if (initialised) return true;
        if (!import.meta.client) return false;
        const {Swiper} = await import('swiper');
        const {Navigation} = await import('swiper/modules');
        if (!nuxtApp.$Swiper && !nuxtApp.$SwiperNavigation) {
            nuxtApp.provide('Swiper', Swiper);
            nuxtApp.provide('SwiperNavigation', Navigation);
        }
        initialised = true;
        return true;
    };

    return {init};
}
